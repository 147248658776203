<template>
  <div class="sleep-screen">
    <div v-if="currentDataSet.length" class="sleep-screen__banners">
      <Banner
          :title="banner.title"
          :image="banner.image"
          v-for="(banner, count) in currentDataSet"
          :key="banner.id"
          v-show="count === index"
      />
    </div>

    <div v-else>
      <Banner
          title="Спящий режим"
      />
    </div>

    <div class="sleep-screen__button" @click="returnToStartScreen">
      <r-button-simple size="larishae" icon="close" type="light" rounded />
      <p class="sleep-screen__button--text edam">
        {{ $t('buttons.touch') }}
      </p>
    </div>

    <div class="sleep-screen__fader">
      <r-fader />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Banner from '@/components/Banner/Banner';

export default {
  name: "SleepScreen",
  components: {Banner},
  computed: {
    ...mapGetters(['GET_BANNERS']),

    currentDataSet() {
      return this.GET_BANNERS;
    },

    bannerRotation() {
      return this.$currentConfig.bannersRotation;
    }
  },

  data() {
    return {
      counter: 100,
      index: 0,
      timeout: null,
    }
  },

  destroyed() {
    if (this.timeout) clearTimeout(this.timeout);
  },

  mounted() {
    this.counter = this.bannerRotation;
    this.countDown();
  },

  watch: {
    counter(count) {
      if (count === 0) {
        if (this.timeout) clearTimeout(this.timeout);
        this.next();
      }
    }
  },

  methods: {
    countDown() {
      if (this.counter) {
        this.timeout = setTimeout(() => {
          --this.counter;
          this.countDown();
        }, 1000);
      }
    },

    next() {
      if (this.timeout) clearTimeout(this.timeout);

      if (this.currentDataSet.length > 1) {
        this.counter = this.bannerRotation;

        if (this.index < this.currentDataSet.length - 1) {
          this.index += 1;
          this.countDown();
        } else {
          this.index = 0;
          this.countDown();
        }
      } else {
        this.index = 0;
        this.counter = this.bannerRotation;
        this.countDown();
      }
    },

    returnToStartScreen() {
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss">
.sleep-screen {
  height: 100%;
  position: relative;
  padding-bottom: 112px;
  
  &__banners {
    height: 100%;
  }

  &__fader {
    position: absolute;
    bottom: 112px;
    left: 0;
    right: 0;
    height: 240px;
    opacity: .64;
  }

  &__button {
    position: absolute;
    bottom: 136px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    .RButtonSimple {
      background-color: #fff !important;
      width: 80px !important;
      height: 80px !important;
      padding: 25px !important;
    }
    .RIcon {
      font-size: 30px !important;
      line-height: 30px !important;
      &:before {
        font-size: 30px !important;
      }
    }
    &--text {
      color: #fff;
      margin-top: 4px;
    }
  }
}
</style>

<template>
  <div class="banner" v-if="image">
    <div class="banner__image px-6" :style="{ 'background-image': `url(${image})` }">
      <div class="banner__title cheddar harakiri--text" v-if="title">
        {{ title }}
      </div>
      
      <div class="parmigiano harakiri--text" v-if="description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',

  props: {
    title: {
      type: String,
      default: () => '',
    },
    image: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
  }
};
</script>

<style lang="scss">
.banner {
  height: 100%;

  &__title {
    margin-bottom: 16px;
    padding-top: 32px;
  }
  
  &__image {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    background-color: var(--rir-amelie);
  }
}
</style>
